@media (max-height: 844px) {
    .responsive-logo-thanks {
        max-height: 86px;
    }

    .responsive-logo-thanks-img {
        top: 23% !important;
    }

    .responsive-text {
        font-size: 14px !important;
    }

    .responsive-frame {
        top: 45% !important;
        transform: translateY(-45%);
    }

    .responsive-logo {
        top: 15% !important;
    }

    .responsive-logo-medal {
        position: relative;
        top: -60px !important;
        object-fit: cover;
    }

    .responsive-logo-medal-details {
        top: -138px !important;
        object-fit: cover;

    }
    .responsive-logo-medal-details-text{
        top: -130px !important;
    }

    .module {
        /* height: 240px; */
    }
    /* .module-img{
        margin: 12px 0px 12px 0px !important;
    } */
    .responsive-text-fs-12{
        font-size: 12px !important;
    }
    .responsive-text-fs-13{
        font-size: 12px !important;
    }
    .responsive-text-fs-14{
        font-size: 12px !important;
    }
    .responsive-text-fs-18{
        font-size: 18px !important;
    }
    .responsive-text-fs-23{
        font-size: 23px !important;
    }
    
}
 

@media  only screen and  (min-width:739px) and (max-width:1023px) {
    .max-height-230{
        max-height: 230px !important;
    }
    .max-height-348{
        object-fit: cover;
        max-height: 348px !important;
    }
}


@media  only screen and  (min-width:1024px)  {
    .max-height-230{
        max-height: 230px !important;
    }
    .max-height-348{
        object-fit: cover;
        max-height: 348px !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) { }
        
/* iphone 4 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) { }

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) { }

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) { }
    
/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) { }

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) { }

/* iphone 12, 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) { }

/* iphone XR, 11 */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) { }
    
/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) { }

/* iphone 12 Pro Max */
@media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) { }
