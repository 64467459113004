:root {
  --second-text-animation: calc(4s / 21);
  --second-text-animation-start: calc(4.4s / 15);
  --second-text-details-animations: 0.5s
}

/* man dau 4.4s man cuoi 4s */
span, label {
  display: inline-block;
  margin-right: 4px;
  color: white;
  opacity: 0;
  filter: blur(4px);
}


label:nth-child(1) {
  animation: fade-in var(--second-text-details-animations) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

label:nth-child(2) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 2) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(3) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 3) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(4) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 4) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(5) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 5) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(6) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 6) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(7) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 7) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(8) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 8) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(9) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 9) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(10) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 10) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(11) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 11) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(12) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 12) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(13) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 13) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(14) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 14) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


label:nth-child(15) {
  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation-start) * 15) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(1) {
  animation: fade-in var(--second-text-details-animations) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 2) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 3) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 4) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 5) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 6) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 7) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 8) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 9) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 10) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 11) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 12) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 13) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 14) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 15) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 16) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 17) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 18) forwards cubic-bezier(0.11, 0, 0.5, 0);
}


span:nth-child(19) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 19) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(20) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 20) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(21) {

  animation: fade-in var(--second-text-details-animations) calc(var(--second-text-animation) * 21) forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {

  100% {
    opacity: 1;
    filter: blur(0);
  }
}