@font-face {
    font-family: 'AvertaStd';
    src: url('./assets/fonts/AvertaStd/AvertaStd-Black.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Black.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Bold.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-BoldItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-ExtraBold.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-ExtraBoldItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Extrathin.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-ExtrathinItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Light.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-LightItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Regular.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-RegularItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Semibold.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-SemiboldItalic.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-Thin.otf'),
    url('./assets/fonts/AvertaStd/AvertaStd-ThinItalic.otf'),
    ;
    font-style: normal;
    font-display: swap;
}


.absolute {
    position: absolute;
}

.pt-page-moveFromLeftFade {

    -webkit-animation: moveFromLeftFade .7s ease both;
    animation: moveFromLeftFade .7s ease both;
}

.pt-page-rotateFoldRight {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-animation: rotateFoldRight .7s both ease infinite;
    animation: rotateFoldRight .7s both ease infinite;
}



@keyframes moveFromLeftFade {
    from {
        opacity: 0.3;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@-webkit-keyframes rotateFoldRight {
    from {}

    to {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotateY(-90deg);
    }
}

/*
@keyframes rotateFoldRight {
    from {}

    to {
        opacity: 0;
        -webkit-transform: translateX(100%) rotateY(90deg);
        transform: translateX(100%) rotateY(90deg);
    }
} */

.text-Chakra-petch {
    font-family: 'Chakra Petch', sans-serif;
}

.text-averta-standard {
    font-family: 'AvertaStd', sans-serif
}

.animate-charcter {
    font-family: 'Chakra Petch', sans-serif;
    text-transform: uppercase;
    /* background: linear-gradient(90.05deg, #413412 5.9%, #A39171 25.31%, #E7D8BD 47.56%, #97825F 69.86%, #C8B997 81.65%); */
    background: linear-gradient(90.01deg, #413412 -2.08%, #A39171 17.35%, #E7D8BD 48.61%, #97825F 75.29%, #C8B997 94.14%);
    background-size: auto auto;
    background-clip: border-box;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: 0px 0px;
    background-size: 200% auto;
    overflow: hidden;
    line-height: 100px !important;
    /* transition: .2s; */
    /* transform: scale(1); */
    /* display: inline-block; */

}
.text-scale-when-active{
}
.animate-charcter-animation {

    animation: textclip 3s , textclipGardient 1s, textScale1 2s 2s;
}

.animate-charcter-animation-gardient {
    animation: textclipGardient 3s linear infinite;
}


.animate-charcter-animation-scaleText {
    animation: scaleText 1s linear infinite;
}

@keyframes textclipGardient {

      100% {
        background-size: 200% auto;
        background-position: 200% 0px;
        /* transform: rotateY(-360deg); */
      }
}

@keyframes textclip {

    0% {
        transform: rotateY(240deg);
      }


      100% {
        transform: rotateY(360deg);

      }
}

.flipX {
    animation: 3s 3s anim-flipX ease normal;
}

@keyframes anim-flipX {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(720deg);
    }
}


.pt-perspective {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

}



.module-border-wrap {
    /* position: relative;
    background: linear-gradient(65.02deg, rgba(255, 255, 255, 0.05) 4.99%, rgba(255, 255, 255, 0.0165) 107.09%);
    border-radius: 18px; */
}


.module {
    color: white;
    border-radius: 18px;
    height: 260px;
    background:
        linear-gradient(to left, #1c2b39, #1a2a37) padding-box,
        linear-gradient(243.38deg, #687687 -3.48%, #38464E 16.6%, #274058 39.46%, #8A92A0 69.34%) border-box;
    color: #313149;
    border: 1px solid transparent;

}

.progress-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 10px 0;
    cursor: pointer;
    padding: 0px 12px;
}

.progress {
    height: 2px !important;
    border-radius: unset !important;
    flex-grow: 1;
    border-radius: unset;
    margin: 0 1px;
    display: flex;
    background-image: -webkit-linear-gradient(left,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(88, 89, 104, .5) 50.001%,
            rgba(88, 89, 104, .5) 100%);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 100% 50%;
    animation-timing-function: linear;
    animation-delay: .2s;
}

.progress.active {
    animation-name: Loader;
}

.progress.passed {
    background-position: 0 0;
}

@-webkit-keyframes Loader {

    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}


iframe {
    z-index: -1 !important;
}

.relative {
    position: relative;
}

body {
    background: #0b1c2b !important;
}


.pt-page-rotateFoldLeft {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-animation: rotateFoldLeft .8s both ease;
    animation: rotateFoldLeft .8s both ease;
}

.pt-page-moveFromRightFade {
    -webkit-animation: moveFromRightFade .8s ease both;
    animation: moveFromRightFade .8s ease both;
}

@-webkit-keyframes rotateFoldLeft {
    from {}

    to {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotateY(-90deg);
    }
}

@keyframes rotateFoldLeft {
    from {}

    to {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotateY(-90deg);
        transform: translateX(-100%) rotateY(-90deg);
    }
}

@-webkit-keyframes moveFromRightFade {
    from {
        opacity: 0.3;
        -webkit-transform: translateX(100%);
    }
}

@keyframes moveFromRightFade {
    from {
        opacity: 0.3;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

.bg {
    /* The image used */
    background-image: url("assets/images/bg_tri_an_khach_hang.svg");

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.text-scale{
    animation: textScale1;
}
.text-scale-2{
    animation: textScale2 2s linear;
   }
   .text-scale-3{
    animation: textScale3 2s linear;
   }


@keyframes textScale1 {
    0% {
      transform: scale(1);
    }
        50% {
      transform: scale(1.1);
    }
    /* 25%{
        transform: scale(1.05);
    } */
    /* 50% {
      transform: scale(1.1);
    }
    75% {
        transform: scale(1.05);
      } */
    100% {
        transform: scale(1);
      }
  }

@keyframes textScale2 {
    0% {
      transform: scale(1);
    }
        50% {
      transform: scale(1.2);
    }
    /* 25%{
        transform: scale(1.05);
    } */
    /* 50% {
      transform: scale(1.1);
    }
    75% {
        transform: scale(1.05);
      } */
    100% {
        transform: scale(1);
      }
  }


@keyframes textScale3 {
    0% {
      transform: scale(1);
    }
        50% {
      transform: scale(1.1);
    }
    /* 25%{
        transform: scale(1.05);
    } */
    /* 50% {
      transform: scale(1.1);
    }
    75% {
        transform: scale(1.05);
      } */
    100% {
        transform: scale(1);
      }
  }


@keyframes textScale5 {
    0% {
      transform: scale(1);
    }
        50% {
      transform: scale(1.1);
    }
    /* 25%{
        transform: scale(1.05);
    } */
    /* 50% {
      transform: scale(1.1);
    }
    75% {
        transform: scale(1.05);
      } */
    100% {
        transform: scale(1);
      }
  }
